import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';

const HereToHelp = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box pt={'4rem'} textAlign={'center'}>
            <Grid container spacing={5} py={'3rem'}>
                <Grid
                    item
                    xs={12}
                    md={4}
                    textAlign={isMobile ? 'center' : 'right'}
                >
                    <img
                        src="\images\standing_chair.jpeg"
                        alt="Nick Schader - Life Coach"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            padding: 0,
                            margin: 0,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={5} py={2} textAlign={'left'} mx={3}>
                    <Typography variant="h3">I'm Here to Help</Typography>
                    <Typography variant="h5">
                        Nick Schader - Life Coach
                    </Typography>
                    <Typography variant="body1" pt={1}>
                        Creating a fulfilling life can be hard. There are so
                        many possible paths, it's hard to know which one to
                        take. Even if you knew where you wanted to go, it's
                        difficult to make the necessary changes. It's scary to
                        step out of your comfort zone and take risks.
                        <br />
                        <br />
                        I've spent the past 20 years searching for the answers.
                        I've read dozens of books, listened to hours of podcasts
                        and watched countless videos on YouTube. And yet, I
                        still didn't have a better life. I would get excited for
                        a short while and maybe make a little progress, but I
                        quickly found myself back where I started. I learned
                        that the key is not in the knowing, it's in the doing.
                        You have to take action!
                        <br />
                        <br />I don't want you to struggle any more. I don't
                        want you to waste any more time. I coach men in their
                        30's and 40's who feel stuck to build the life they want
                        using my 3-month Renewed Man Coaching Program. I use a
                        3-step process to guide you through:
                        <li>
                            Building an empowering MINDSET so you can move
                            forward without fear or limiting beliefs
                        </li>
                        <li>
                            Creating a VISION so you know what you want without
                            feeling lost
                        </li>
                        <li>
                            Making an ACTION PLAN so you can build the life you
                            want without feeling overwhelmed
                        </li>
                        <br />
                        The first step is critical. Having a vision and an
                        action plan will only get you so far. If you want to go
                        farther, you have to upgrade your mindset! With the
                        right tools and support, you can crush your fears,
                        shatter your limiting beliefs, and unlock your full
                        potential.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HereToHelp;
