import React from 'react';
import { Box, Typography, Grid, useTheme } from '@mui/material';

const TopIntro = () => {
    const theme = useTheme();

    return (
        <Box mt={'2.5rem'}>
            <Box
                sx={{
                    backgroundImage:
                        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/images/coaching_1.jpeg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: theme.palette.common.white,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                ></Box>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    mt={'8rem'}
                >
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" textAlign={'center'}>
                            Build The Life You Want
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="h6" textAlign={'center'} sx={{}}>
                    ONE-ON-ONE COACHING TO GUIDE MEN TO A BETTER LIFE
                </Typography>
            </Box>
            <Box
                sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    py: 2,
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    width: '100%',
                    backgroundImage:
                        'radial-gradient(at center center, #475B70 0%, #253342 100%)',
                    color: theme.palette.common.white,
                }}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs={12} md={2}>
                        <Typography variant="h5">MINDSET</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h5">VISION</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h5">ACTION PLAN</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default TopIntro;
